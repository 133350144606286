import React, { useState } from 'react';
import PropTypes from 'prop-types';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import FormArrow from '../images/form-arrow.inline.svg';

export const EmailListForm = ({ title }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    addToMailchimp(email, {
      'group[13657][2]': '2',
    })
      .then((data) => {
        setMessage(data.msg);
      })
      .catch(() => {
        setMessage('sorry something has gobe wrong, please try again');
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <form onSubmit={(e) => { handleSubmit(e); }}>
      <p>{title}</p>
      <div id="mc_embed_signup_scroll" className="signup-form-wrapper">
        <div className="mc-field-group col-sm-9 col-xs-5">
          <input
            className="fancy-border"
            placeholder="Email address"
            name="email"
            type="text"
            onChange={(e) => { handleEmailChange(e); }}
          />
        </div>
        <button className="arrow button" type="submit" name="subscribe" aria-label="Subscribe" id="mc-embedded-subscribe">
          <FormArrow />
        </button>
        <div
          className="errors"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </div>
    </form>
  );
};

EmailListForm.propTypes = {
  title: PropTypes.string.isRequired,
};
