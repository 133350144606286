import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Header } from './header';
import { Footer } from './footer';
import { Guides } from './guides';
import { Acknowledgement } from './acknowledgement';
import { setCSSVariable } from '../helpers/set-css-variable';
import 'slick-carousel/slick/slick.css';
import '../assets/styles/index.scss';

export const Layout = ({
  wrapperClass,
  bgcolor,
  accentColor,
  children,
  showAcknowledgement,
  showLocations,
}) => {
  if (accentColor === null) {
    accentColor = {
      hex: '#988e79',
    };
  }
  const bgColorSettings = (color) => {
    switch (color) {
      case 'White':
        return {
          bgColor: '#ffffff',
          theme: 'theme-dark',
        };
      case 'Off white':
        return {
          bgColor: '#EFEEE8',
          theme: 'theme-dark',
        };
      case 'Charcoal':
        return {
          bgColor: '#1A1A1A',
          theme: 'theme-light',
        };
      default:
        return {
          bgColor: '#ffffff',
          theme: 'theme-dark',
        };
    }
  };

  setCSSVariable('--accent-color', accentColor?.hex);
  setCSSVariable('--bg-color', bgColorSettings(bgcolor).bgColor);

  const [paddingTopClass, setPaddingTopClass] = useState('');
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (typeof window !== 'undefined' && window.location.pathname !== '/') {
      setPaddingTopClass('page-wrapper--padding-top');
    }
  }, []);

  return (
    <div
      className={`${wrapperClass} ${bgColorSettings(bgcolor).theme} ${paddingTopClass} page-wrapper guides`}
      style={{ backgroundColor: `${bgColorSettings(bgcolor).bgColor}` }}
    >
      {showAcknowledgement && (
        <Acknowledgement />
      )}
      <Header accentColor={accentColor} showLocations={showLocations} />
      <main>{children}</main>
      <Guides accentColor={accentColor} />
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  wrapperClass: PropTypes.string,
  bgcolor: PropTypes.string.isRequired,
  accentColor: PropTypes.shape().isRequired,
  showAcknowledgement: PropTypes.bool,
  showLocations: PropTypes.bool,
};

Layout.defaultProps = {
  wrapperClass: '',
  showAcknowledgement: false,
  showLocations: false,
};
