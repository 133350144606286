import React, { useEffect } from 'react';

const AccessibeScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
    script.async = true;

    script.onload = () => {
      window.acsbJS.init({
        statementLink: '',
        footerHtml: '',
        hideMobile: false,
        hideTrigger: false,
        disableBgProcess: false,
        language: 'en',
        position: 'left',
        leadColor: '#1c1c1c',
        triggerColor: '#00ff00',
        triggerRadius: '50%',
        triggerPositionX: 'right',
        triggerPositionY: 'bottom',
        triggerIcon: 'people',
        triggerSize: 'medium',
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
          triggerSize: 'small',
          triggerPositionX: 'left',
          triggerPositionY: 'top',
          triggerOffsetX: 10,
          triggerOffsetY: 0,
          triggerRadius: '50%',
        },
      });
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null;
};

export default AccessibeScript;
