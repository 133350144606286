/* global document */
import { useEffect } from 'react';

export function setCSSVariable(name, value) {
  useEffect(() => {
    document.documentElement.style.setProperty(
      `${name}`,
      `${value}`,
    );
  }, []);
}
