import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

/* global window */
const cookieName = "acknowledgementModel"
const getCookie = cname => {
  if (typeof window !== "undefined") {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(window.document.cookie)
    const ca = decodedCookie.split(";")
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i]
      while (c.charAt(0) === " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
  }
  return ""
}

const setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`

  if (typeof window !== "undefined") {
    window.document.cookie = `${cname}=${cvalue};${expires};path=/;SameSite=None;Secure`
  }
}

export const Acknowledgement = () => {
  const gatsbyRepoData = useStaticQuery(graphql`
    query {
      datoCmsAcknowledgement {
        text
        timeout
        backgroundColor {
          hex
        }
        showToUserEvery
      }
    }
  `)
  const {
    timeout,
    text,
    backgroundColor,
    showToUserEvery,
  } = gatsbyRepoData.datoCmsAcknowledgement

  useEffect(() => {
    if (typeof window !== "undefined") {
      const cookie = getCookie(cookieName)
      if (cookie === "") {
        const $body = window.document.body
        $body.classList.add("open-acknowledgement")

        const timeoutFunc = () => {
          if ($body.classList.contains("open-acknowledgement")) {
            $body.classList.remove("open-acknowledgement")
          }
        }

        setTimeout(timeoutFunc, parseInt(timeout, 10))
        setCookie(cookieName, "true", Number(showToUserEvery))
      }
    }
  }, [gatsbyRepoData])

  const closeAcknowledgement = () => {
    if (typeof window !== "undefined") {
      window.document.body.classList.remove("open-acknowledgement")
    }
  }

  return (
    <div
      className="acknowledgement-wrapper"
      style={{ backgroundColor: `${backgroundColor.hex}` }}
      role="button"
      tabIndex={0}
      onClick={e => {
        e.preventDefault()
        closeAcknowledgement()
      }}
      onKeyDown={closeAcknowledgement()}
    >
      <div className="container row">
        <div className="col-sm-10 offset-sm-2 col-xs-6 col">
          <h2 className="gothic--medium">{text}</h2>
        </div>
      </div>
    </div>
  )
}
