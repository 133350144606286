/* eslint-disable no-undef */
import { useEffect } from 'react';
/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key (ie, Escape)
 * @param {function} action - the action to perform on key press
 */

export function useKeypress(key, action) {
  useEffect(() => {
    function onKeyup(e) {
      // eslint-disable-next-line no-console
      if (e.key === key) action();
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, []);
}
