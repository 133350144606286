/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Navigation } from './navigation';
import { useOuterClick } from '../helpers/use-outer-click';

/* global window */
export const Header = ({ accentColor, showLocations }) => {
  const locationData = useStaticQuery(graphql`
    query {
      datoCmsHome {
        gertrudeContemporaryOpeningTimes
        gertrudeGlasshouseOpeningTimes
      }
    }
  `);
  const [showMenu, setShowMenu] = useState(false);
  const [menuColor, setMenuColor] = useState('#948975');
  const headerRef = useOuterClick((ev) => {
    if (ev && showMenu) {
      setShowMenu(false);
    }
  });

  useEffect(() => {
    if (accentColor !== null) {
      setMenuColor(accentColor.hex);
    }
    // Force external links to open in a new tab, unless its an email or phone number
    [...window.document.links].forEach((link) => {
      const { hostname } = link;
      if (hostname.includes('tel:') || hostname.includes('mailto:')) {
        return;
      }
      if (hostname !== window.location.hostname) {
        link.target = '_blank';
      }
    });
  }, []);

  function closeMenu() {
    setShowMenu(false);
  }

  return (
    <header ref={headerRef}>
      <nav className="header-nav" aria-label="header">
        <button
          id="navbutton"
          className={`${showMenu ? 'active' : ''} menu-circle`}
          style={{ backgroundColor: `${menuColor}` }}
          aria-expanded={showMenu}
          aria-controls="nav"
          aria-labelledby="nav-main"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setShowMenu(!showMenu);
          }}
          onMouseEnter={() => {
            setMenuColor('#00ff00');
          }}
          onMouseLeave={() => {
            setMenuColor(accentColor.hex);
          }}
        />
        <Link className="logo-wrapper col-sm-2" to="/">
          <svg className="logo" width="194" height="28" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 218.5 33" fill="none">
            <g>
              <g>
                <path d="M0,0v33h24.8V12.4H12.4v8.3h4.1v4.1H8.3V8.3h16.5V0H0z M27.7,0v33h24.8v-8.3H35.9v-4.1H46v-8.3H35.9V8.3h16.5V0H27.7z
                  M63.6,8.3h8.3v8.3h-8.3V8.3z M55.4,0v33h8.3v-8.3h2.1l4.1,8.3h10.3L76,24.8h4.1V0H55.4z M83,0v8.3h8.3V33h8.3V8.3h8.3V0H83z
                  M119,8.3h8.3v8.3H119V8.3z M110.7,0v33h8.3v-8.3h2.1l4.1,8.3h10.3l-4.1-8.3h4.1V0H110.7z M154.9,0v24.8h-8.3V0h-8.3v33h24.8V0
                  H154.9z M174.3,8.3h8.3v16.5h-8.3V8.3z M166.1,0v33h20.7l4.1-4.1V4.1L186.7,0H166.1z M193.8,0v33h24.8v-8.3H202v-4.1h10.1v-8.3
                  H202V8.3h16.5V0H193.8z"
                />
              </g>
            </g>
          </svg>
          <span className="vh">home</span>
        </Link>
      </nav>
      <Navigation showMenu={showMenu} closeMenu={closeMenu} />
      <section aria-labelledby="location-info" className="location-information container row">
        <h2 id="location-info" className="vh">Hours of operation</h2>
        <div className="col col-sm-2 col-xs-3" />
        <div className="col col-sm-4 hidden-xs header-location">
          {showLocations && (
            <div
              className="sans--small"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: locationData.datoCmsHome.gertrudeContemporaryOpeningTimes,
              }}
            />
          )}
        </div>
        <div className="col col-sm-4 offset-sm-1 hidden-xs header-location">
          {showLocations && (
            <div
              className="sans--small"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: locationData.datoCmsHome.gertrudeGlasshouseOpeningTimes,
              }}
            />
          )}
        </div>
      </section>
    </header>
  );
};

Header.propTypes = {
  accentColor: PropTypes.shape().isRequired,
  showLocations: PropTypes.bool.isRequired,
};
