import React from 'react';
import PropTypes from 'prop-types';

export const Guides = ({ accentColor }) => {
  if (accentColor) {
    return (
      <>
        <div className="guideline left-guideline" style={{ backgroundColor: `${accentColor.hex}` }} />
        <div className="guideline center-guideline" style={{ backgroundColor: `${accentColor.hex}` }} />
        <div className="guideline right-guideline" style={{ backgroundColor: `${accentColor.hex}` }} />
      </>
    );
  }

  return null;
};

Guides.propTypes = {
  accentColor: PropTypes.shape().isRequired,
  // guidelinePercent: PropTypes.number.isRequired,
};
