import { useEffect, useRef } from 'react';

/* global document */
export function useOuterClick(callback) {
  const callbackRef = useRef(); // initialize mutable callback ref
  const innerRef = useRef(); // returned to client, who sets the "border" element

  // update callback on each render, so second useEffect has most recent callback
  useEffect(() => { callbackRef.current = callback; });
  useEffect(() => {
    function handleClick(e) {
      if (innerRef.current && callbackRef.current
        && !innerRef.current.contains(e.target)
      ) callbackRef.current(e);
    }
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
}
