import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { EmailListForm } from './signupForm';
import Facebook from '../images/facebook.inline.svg';
import Twitter from '../images/twitter.inline.svg';
import Instagram from '../images/instagram.inline.svg';
import AccessibeScript from './accessibeScript';


export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsFooter {
        twitter
        instagram
        facebook
        acknowledgement
        contactDetails
        gertrudeGlasshouse
        gertrudeContemporary
        newsletterSignupTitle
        copyright
      }
    }
  `);

  const footer = data.datoCmsFooter;
  return (
    <footer role="contentinfo" className="footer">
      <div className="container row">
        <div className="col-sm-5 offset-sm-2 col-xs-6 col">
          <div
            className="footer-acknowledgement"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: footer.acknowledgement,
            }}
          />
          <EmailListForm title={footer.newsletterSignupTitle} />
        </div>
        <div
          className="col-sm-3 offset-sm-1 col-xs-3 col"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: footer.gertrudeContemporary,
          }}
        />
        <div
          className="col-sm-3 col col-xs-3"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: footer.gertrudeGlasshouse,
          }}
        />
      </div>
      <div className="container row">
        <div className="col-sm-5 offset-sm-2 col-xs-6 col contact-us-wrapper">
          <Link className="link supporters" to="/supporters">Gertrude’s community of supporters</Link>
          <br />
          <Link className="link annual-reports" to="/annual-reports">Annual reports</Link>
        </div>
        <div
          className="col-sm-3 offset-sm-1 col-xs-3 col contact-us-wrapper"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: footer.contactDetails,
          }}
        />
      </div>
      <div className="container row">
        <div className="col-sm-3 col offset-sm-8 col-xs-3 offset-xs-3 social-icon-wrapper">
          {footer.facebook && (
            <a href={footer.facebook} rel="noopener noreferrer">
              <Facebook />
            </a>
          )}
          {footer.instagram && (
            <a href={footer.instagram} rel="noopener noreferrer">
              <Instagram />
            </a>
          )}
          {footer.twitter && (
            <a href={footer.twitter} rel="noopener noreferrer">
              <Twitter />
            </a>
          )}
        </div>
      </div>
      <div className="container row">
        <div className="col-sm-5 offset-sm-2 col-xs-3 offset-xs-3 col copyright">
          ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          {footer.copyright}
          <br />
          Website by
          {' '}
          <a href="https://ellewilliams.com" target="_blank" rel="noreferrer">Elle Williams Studio</a>
          {' '}
          &
          {' '}
          <a href="https://srrycmpny.com" target="_blank" rel="noreferrer">Sorry Company</a>
          .
        </div>
      </div>
			<AccessibeScript />
    </footer>
  );
};
