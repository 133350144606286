import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { useKeypress } from '../helpers/use-keypress';

export const Navigation = ({ showMenu, closeMenu }) => {
  const [menuList, setMenuList] = useState([]);
  const data = useStaticQuery(graphql`
    query {
      datoCmsNavigation {
        navList {
          ... on DatoCmsNavHeading {
            id
            heading
          }
          ... on DatoCmsNavLink {
            id
            link
            text
          }
        }
        description
      }
    }
  `);

  const nav = data.datoCmsNavigation;

  function handleCloseMenu() {
    closeMenu();
  }

  useKeypress('Escape', handleCloseMenu);

  useEffect(() => {
    const newMenu = [];
    let menuHeading = '';
    data.datoCmsNavigation.navList.map((node) => {
      if (node.heading) {
        menuHeading = node.heading;
        newMenu[node.heading] = [];
      } else {
        newMenu[menuHeading].push({
          link: node.link,
          text: node.text,
          id: node.id,
        });
      }

      return newMenu;
    });
    setMenuList(newMenu);
  }, []);

  const renderLink = (item) => {
    if (item.link.includes('http')) {
      return <a href={item.link} key={item.id} target="_blank" rel="noopener noreferrer">{item.text}</a>;
    }
    return <Link to={item.link} key={item.id}>{item.text}</Link>;
  };

  return (
    <>
      <div id="nav" className={`col-sm-4 col navigation ${showMenu ? 'active' : 'hidden'}`}>
        <section
          aria-hidden={!showMenu}
          aria-labelledby="nav-main"
          className="navigation-list container row"
        >
          <h2 id="nav-main" className="vh">Main Navigation</h2>
          <Link
            aria-label="Search Website"
            to="/search"
            className="search-link"
          >
            Search
          </Link>
          {Object.keys(menuList).map((node) => (
            <nav aria-label={node} key={node} className="col col-sm-7 col-xs-3 nav-col">
              <h3 className="sans--regular">{node}</h3>
              {menuList[node].map((item) => (
                renderLink(item)
              ))}
            </nav>
          ))}
        </section>
        <section aria-labelledby="nav-about" className="container">
          <h2 id="nav-about" className="vh">About Gertrude</h2>
          <div
            className="description sans--small col offset-sm-7 offset-xs-3 col-sm-7 col-xs-3"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: nav.description,
            }}
          />
          <div className="description sans--small col offset-sm-7 offset-xs-3 col-sm-7 col-xs-3">
            <Link className="link" to="/supporters">Gertrude’s community of supporters</Link>
          </div>
        </section>
      </div>
    </>
  );
};

Navigation.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
};
